import React, { Component } from 'react';
import '../../Modal.css';
import { connect } from 'react-redux';
import { updateSelectedPartnerActivity } from '../../../../actions/adminActions/partnerActivitiesActions';
import { activityPartners } from '../../../../apis/backend';

class EditModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      id: '',
      partner: '',
      activity: '',
      startTime: '',
      startDate: '',
      endTime: '',
      endDate: ''
    };
  }

  clearState() {
    this.setState({
      id: '',
      partner: '',
      activity: '',
      startTime: '',
      startDate: '',
      endTime: '',
      endDate: ''
    });
  }

  getDate(time) {
    if (!time) return '';
    const date = new Date(time);
    return date.toLocaleDateString('en-CA'); // Format YYYY-MM-DD for input[type="date"]
  }

  getTime(time) {
    if (!time) return '';
    const date = new Date(time);
    return date.toLocaleTimeString('en-GB', { hour: '2-digit', minute: '2-digit' }); // Format HH:mm for input[type="time"]
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (
      prevProps.selectedPartnerActivity !== this.props.selectedPartnerActivity &&
      this.props.selectedPartnerActivity !== 0
    ) {
      const partnerActivity = this.props.partnerActivities.find(
        (obj) => obj.id === this.props.selectedPartnerActivity
      );
      if (partnerActivity) {
        this.setState({
          activity: partnerActivity.activity,
          startTime: this.getTime(partnerActivity.startTime),
          startDate: this.getDate(partnerActivity.startTime),
          endTime: this.getTime(partnerActivity.endTime),
          endDate: this.getDate(partnerActivity.endTime)
        });
      }
    }
  }

  async partnerActivityRequest() {
    try {
      const startDateTimeSet = this.state.startDate != '' && this.state.startTime != '';
      const endDateTimeSet = this.state.endDate != '' && this.state.endTime != '';

      await activityPartners
        .patch(
          `/${this.props.selectedPartnerActivity}`,
          {
            partner: this.props.selectedPartner,
            activity: this.state.activity,
            startTime: startDateTimeSet ? this.state.startDate + 'T' + this.state.startTime : null,
            endTime: endDateTimeSet ? this.state.endDate + 'T' + this.state.endTime : null
          },
          { headers: { authorization: this.props.employeeToken } }
        )
        .then(this.clearState())
        .catch((response) => {
          console.log(response);
          alert('Sorry, an error occurred. Please check your entries.');
        });
    } catch (err) {
      console.log('Request Failed.');
    }
  }

  render() {
    return (
      <div id="exampleModal" className="modal fade" style={{ overflow: 'scroll' }}>
        <div className="modal-dialog modal-dialog-centered lg">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">Update Partner Activity</h5>
              <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <div className="input-group input-group-sm mb-3">
                <div className="input-group-prepend">
                  <span className="input-group-text" id="inputGroup-sizing-sm">
                    Partner Activity Name
                  </span>
                </div>
                <select
                  className="form-control"
                  value={this.state.activity}
                  onChange={(e) => this.setState({ activity: e.target.value })}
                >
                  <option value="" disabled>
                    Select Activity
                  </option>
                  {this.props.activities.map((activity) => (
                    <option value={activity.id} key={activity.id}>
                      {activity.name}
                    </option>
                  ))}
                </select>
              </div>
            </div>
            <div className="input-group input-group-sm mb-3">
              <div className="input-group-prepend">
                <span className="input-group-text" id="inputGroup-sizing-sm">
                  Start Date
                </span>
              </div>
              <input
                type="date"
                className="form-control"
                aria-label="Sizing example input"
                aria-describedby="inputGroup-sizing-sm"
                value={this.state.startDate}
                onChange={(e) => this.setState({ startDate: e.target.value })}
              />
            </div>
            <div className="input-group input-group-sm mb-3">
              <div className="input-group-prepend">
                <span className="input-group-text" id="inputGroup-sizing-sm">
                  Start Time
                </span>
              </div>
              <input
                type="time"
                className="form-control"
                aria-label="Sizing example input"
                aria-describedby="inputGroup-sizing-sm"
                value={this.state.startTime}
                onChange={(e) => this.setState({ startTime: e.target.value })}
              />
            </div>
            <div className="input-group input-group-sm mb-3">
              <div className="input-group-prepend">
                <span className="input-group-text" id="inputGroup-sizing-sm">
                  End Date
                </span>
              </div>
              <input
                type="date"
                className="form-control"
                aria-label="Sizing example input"
                aria-describedby="inputGroup-sizing-sm"
                value={this.state.endDate}
                onChange={(e) => this.setState({ endDate: e.target.value })}
              />
            </div>
            <div className="input-group input-group-sm mb-3">
              <div className="input-group-prepend">
                <span className="input-group-text" id="inputGroup-sizing-sm">
                  End Time
                </span>
              </div>
              <input
                type="time"
                className="form-control"
                aria-label="Sizing example input"
                aria-describedby="inputGroup-sizing-sm"
                value={this.state.endTime}
                onChange={(e) => this.setState({ endTime: e.target.value })}
              />
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary"
                data-dismiss="modal"
                onClick={() => this.clearState()}
              >
                Cancel
              </button>
              <button
                type="button"
                className="btn btn-dark"
                data-dismiss="modal"
                onClick={() => {
                  this.partnerActivityRequest();
                }}
                disabled={!this.state.activity != ''}
              >
                Save changes
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  activities: state.admin.admin.activities,
  selectedPartner: state.admin.partners.selectedPartner,
  partnerActivities: state.admin.admin.partnerActivities,
  selectedPartnerActivity: state.admin.partnerActivities.selectedPartnerActivity,
  employeeToken: state.auth.employeeToken
});

export default connect(mapStateToProps, { updateSelectedPartnerActivity })(EditModal);
